import { useEffect, useRef, useState } from "react";

// material-ui
import { CircularProgress, Grid, Typography } from "@mui/material";

// project-imports
import ComponentWrapper from "sections/ComponentWrapper";
import ComponentSkeleton from "sections/ComponentSkeleton";

// ==============================|| COMPONENTS - PROGRESS ||============================== //

const Loader2 = ({ text }: any) => {
  const [bufferProgress, setBufferProgress] = useState(0);
  const progressRef = useRef(() => {});
  useEffect(() => {
    progressRef.current = () => {
      if (bufferProgress > 100) {
        setBufferProgress(0);
      } else {
        const diff = Math.random() * 10;
        // const diff2 = Math.random() * 10;
        setBufferProgress(bufferProgress + diff);
      }
    };
  });

  useEffect(() => {
    const bufferTimer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(bufferTimer);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: text ? "white" : "none",
        borderRadius: text ? "18px" : "0px",
      }}
    >
      <ComponentSkeleton>
        <ComponentWrapper sx={{ p: "24px !important", mb: "0px !important" }}>
          {/* <Stack spacing={3}> */}
          <Grid container>
            <Grid item textAlign={"center"}>
              <CircularProgress size={50} />
              {text && (
                <Typography>
                  Avoid refreshing; verification takes time.
                </Typography>
              )}
            </Grid>
          </Grid>
          {/* </Stack> */}
        </ComponentWrapper>
      </ComponentSkeleton>
    </div>
  );
};

export default Loader2;
