import {
  Grid,
  Typography,
  Link,
  Box,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import { Stack } from "@mui/system";
// import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import TwitterIcon from "images/social_login/ic_twitter_new.png";
import emailIcon from "images/mail-sm.png";
import InstagramIcon from "images/Insta.png";
import FacebookIcon from "images/ic_fb.png";
// import TwitterIcon from "images/twitter.png";

const footerStyle = {
  backgroundColor: "#0A4A8A",
  borderTop: "radial-gradient(circle at 50% 50%, #2b2b2b 0%, #000000 100%)",
  padding: "32px 0",
  paddingBottom: "16px",
  // marginTop: "16px",
  zIndex: 10000000,
};

const logoStyle = {
  textDecoration: "none",
  color: "#FFFFFF",
  fontFamily: "Passion One, sans-serif",
  letterSpacing: "2px",
  fontSize: { xs: "22px", sm: "30px", md: "35px", lg: "35px" },
  fontWeight: 700,
  lineHeight: "70px",
  userSelect: "none",
  pointerEvents: "none",
};

const descriptionStyle = {
  color: "#FFFFFF",
  fontSize: { xs: "12px", sm: "12px", md: "14px", lg: "14px" },
  lineHeight: "20px",
  fontWeight: "200",
  mr: 8,
  //   padding: "10px",
};

const FooterTitle = {
  pointerEvents: "none",
  lineHeight: "70px",
  color: "#FFFFFF",
  fontWeight: "600",
  fontSize: { xs: "16px", sm: "20px", md: "24px", lg: "24px" },
};
const FooterContent = {
  color: "#FFFFFF",
  fontWeight: "400",
  fontSize: { xs: "14px", sm: "16px", md: "18px", lg: "18px" },
  lineHeight: "50px",
  cursor: "pointer",
  marginLeft: 1,
};

const copyrightStyle = {
  //   marginTop: "16px",
  fontSize: "0.8rem",
  color: "#FFFFFF",
  // fontWeight: "bold",
};

const Footer = () => {
  const { organization }: any = useAuth();
  const theme = useTheme();
  //   const navigate = useNavigate();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Passion+One&display=swap"
        />
      </>
      <footer style={footerStyle}>
        {/* <Container> */}
        <Grid
          sx={{
            ml: { lg: 10, md: 4.5, sm: 5.5, xs: 2 },
            mr: { md: 6.5, lg: 10 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",

              flexDirection: downSM ? "column" : "row",
            }}
          >
            <Box>
              <Typography variant="h4" sx={logoStyle}>
                {organization?.name ? organization?.name : "AUTOMATCHPROS"}
              </Typography>
              <Typography variant="body2" sx={descriptionStyle}>
                Welcome to AUTOMATCHPROS – where car buying meets personalized
                expertise! Connect with skilled sales consultants for a tailored
                and informed journey. Discover the perfect vehicle with
                professionals dedicated to simplifying your car-buying
                experience. Join us in revolutionizing how you find and purchase
                your dream car. Drive confidently with AUTOMATCHPROS.
              </Typography>
            </Box>

            <Box>
              <Stack>
                <Typography sx={FooterTitle}>Contact</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <img
                    style={{
                      width: "22x",
                      height: "22px",
                      //   maxWidth: "90%",
                      maxHeight: "90%",
                    }}
                    src={emailIcon}
                    alt="EmailIcon"
                  />
                  <Link
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: "400",
                      fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: "14px",
                        lg: "14px",
                      },
                      lineHeight: "50px",
                    }}
                  >
                    <a
                      style={{ color: "#FFFFFF" }}
                      href="mailto:support@automatchpros.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      support@automatchpros.com
                    </a>
                  </Link>
                </Stack>
                <div style={{ textAlign: "left", marginTop: "8px" }}>
                  {/* <Typography sx={FooterTitle}>Social Media</Typography> */}
                  <a
                    href="https://www.instagram.com/automatchpros/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        maxWidth: "100%",
                        maxHeight: "100%",
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                      src={InstagramIcon}
                      alt="InstagramIcon"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/automatchpros"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        maxWidth: "100%",
                        maxHeight: "100%",
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                      src={FacebookIcon}
                      alt="FacebookIcon"
                    />
                  </a>
                  <a
                    href="https://twitter.com/automatchpros"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        maxWidth: "100%",
                        maxHeight: "100%",
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                      src={TwitterIcon}
                      alt="TwitterIcon"
                    />
                  </a>
                </div>
              </Stack>
            </Box>
          </Box>
          <br />
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box>
              <Typography
                variant="body2"
                sx={{ "& p": { marginTop: "0px !important" } }}
                style={copyrightStyle}
              >
                &copy; {new Date().getFullYear()}
                {organization?.name ? organization?.name : " AUTOMATCHPROS"}.
                All rights reserved. Various trademarks held by their respective
                owners.
              </Typography>
            </Box>
            <Box>
              <div
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Link
                  href="https://www.automatchpros.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="#0A4A8A"
                  sx={{ marginRight: "10px" }}
                >
                  <Typography sx={FooterContent}>Privacy</Typography>
                </Link>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "auto",
                      minHeight: "24px",
                      minWidth: "2px",
                      backgroundColor: "#ffff",
                    }}
                  ></Box>
                </Box>
                <Link
                  href="https://www.automatchpros.com/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="#0A4A8A"
                  sx={{ marginRight: "10px" }}
                >
                  <Typography sx={FooterContent}>Terms</Typography>
                </Link>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "auto",
                      minHeight: "24px",
                      minWidth: "2px",
                      backgroundColor: "#ffff",
                    }}
                  ></Box>
                </Box>
                <Link
                  href="https://www.automatchpros.com/disclaimer"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="#0A4A8A"
                  sx={{ marginRight: "10px" }}
                >
                  <Typography sx={FooterContent}>Disclaimer</Typography>
                </Link>
              </div>
            </Box>
          </Box>
          {/* <Grid item xs={12}></Grid> */}
        </Grid>
        {/* </Container> */}
      </footer>
    </>
  );
};

export default Footer;
