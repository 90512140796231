import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  Box,
  TextField,
  Avatar,
  Menu,
  MenuItem,
  Badge,
  Paper,
  Popper,
  useMediaQuery,
  useTheme,
  ClickAwayListener,
  Stack,
  List,
  ListItemText,
  DialogTitle,
  Dialog,
  ListItem,
  Divider,
  Tooltip,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { getAllSearchData, AddSearchedData } from "services/filterCar.service";
import {
  ArrowDown2,
  CloseCircle,
  CloseSquare,
  Heart,
  Home,
  Information,
  LogoutCurve,
  Message,
  Notification,
  SearchNormal1,
  User,
  // UserOctagon,
} from "iconsax-react";
import { dispatch } from "store";
import {
  setFilterSearch,
  setSearches,
  setSearchedData,
} from "store/reducers/landing";
import useAuth from "hooks/useAuth";
import { blue } from "@mui/material/colors";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Snackbar from "utils/Snackbar";
import { Autocomplete } from "@mui/material";
import Transitions, { PopupTransition } from "components/@extended/Transitions";
import MainCard from "components/MainCard";
import {
  setNotification,
  setNotificationCount,
  setRemoveMessageId,
} from "store/reducers/notification";
import {
  getAllNotification,
  readAllNotification,
  readSingleNotification,
} from "services/notification.service";
import { formatDate, getRoomId } from "utils/helper";
import events from "../utils/events";
import BuyerModal from "components/BuyerModal";
import { setSettingCount } from "store/reducers/user";
import {
  resetActiveChannel,
  resetActiveChannelMessages,
  setActiveChannelUser,
  setChatIdeal,
  setChatSkip,
  setCurrentRecieverId,
  setTotalChatCount,
  setUserSwitching,
} from "store/reducers/chat";

const Navbar2 = () => {
  const { user, logout, socket, updateLogin, isLoggedIn }: any = useAuth();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const searches = useSelector((state: any) => state.landing)?.filter?.search;
  const searchedData = useSelector(
    (state: any) => state?.landing?.searchedData
  );
  const options = searchedData.map((data: any) =>
    data.keyword?.toString().replace(/,/g, " ")
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState<any>(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    // console.log(user != null && user !== undefined && isLoggedIn);
    if (user != null && user !== undefined && isLoggedIn && consent2) {
      getAllSearchData({ userId: user?._id })
        .then((data: any) => {
          dispatch(setSearchedData(data?.data?.data));
        })
        .catch((error: any) => {
          Snackbar(
            error.response.data.message
              ? error.response.data.message
              : "something went wrong.",
            "error"
          );
        });
    }
  }, []);
  useEffect(() => {
    if (search && (!searches || searches.length === 0)) {
      dispatch(setFilterSearch([]));
      setSearch("");
    }
  }, [searches]);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (value: any, status: any) => {
    let statusCode = status;
    if (value || value === "") {
      if (statusCode === 1) {
        dispatch(setFilterSearch({ key: "search", value }));
        // setSearch(value);
        setSearch("");
        AddCurrentSearched(value);
        navigate("/home");
      }
    } else {
      dispatch(setFilterSearch([]));
      setSearch(value);
    }
  };
  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement> | any
  ) => {
    // Checking for whitespace followed by alphanumeric character and replacing it with a single space
    const value = /\s+(?=[a-zA-Z0-9])/.test(event.target.value)
      ? event.target.value.replace(/\s+(?=[a-zA-Z0-9])/, " ").trim()
      : event.target.value[0] === " "
      ? event.target.value.slice(1).trim()
      : event.target.value;
    if (
      (event.key === "Enter" || event.keyCode === 13) &&
      event.keyCode !== 8
    ) {
      handleSearch(value, 1);
    }
  };

  const [dialogOpen2, setDialogOpen2] = useState(false);

  const handleOpenDialog2 = () => {
    setDialogOpen2(true);
  };

  const handleCloseDialog2 = () => {
    setDialogOpen2(false);
  };

  const handleLogout = () => {
    logout(user?._id);
    // console.log(socket);
    socket.emit(events.LOGOUT, { nickName: user?._id });
    dispatch(resetActiveChannelMessages([]));
    dispatch(resetActiveChannel());
    handleClose();
    handleCloseDialog2();
  };
  const AddCurrentSearched = (value: any) => {
    let reqData = {
      userId: user?._id,
      keyword: value?.toString().replace(/,/g, " "),
    };
    value &&
      AddSearchedData(reqData)
        .then(() => {
          const searchedDatass = searchedData.filter(
            (searches: any) =>
              searches?.keyword === value?.toString().replace(/,/g, " ") ||
              searches?.keyword === value
          );
          if (searchedDatass.length === 0) {
            dispatch(
              setSearches({
                _id: null,
                keyword: value?.toString().replace(/,/g, " "),
              })
            );
          }
        })
        .catch((err: any) => {
          Snackbar(err.message, "error");
        });
  };

  const navigate = useNavigate();
  // const handleDrawer = () => {
  //   setOpen(!open);
  // };
  const notification = useSelector(
    (state: any) => state?.notification.notification
  );
  const currentUrl = window.location.href;
  const data = useSelector((state: any) => state?.homepage?.data);
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
  const anchorRef = useRef<any>(null);
  useEffect(() => {
    getNotification();
  }, []);
  const [BuyerOpen, setBuyerOpen] = useState(false);
  const [BuyerId, setBuyerId] = useState("");

  const handleCloseBuyer = () => {
    setBuyerOpen(false);
  };
  const getNotification = () => {
    if (
      user != null &&
      user !== undefined &&
      isLoggedIn &&
      consent &&
      !location.pathname.startsWith("/myaccount")
    ) {
      getAllNotification()
        .then((data) => {
          dispatch(setNotification(data?.data?.data?.data));
          dispatch(setNotificationCount(data?.data?.data?.Count));
        })
        .catch((err) => {
          Snackbar(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "Error, while loading data",
            "error"
          );
        });
    }
  };
  const markAllAsRead = () => {
    readAllNotification().then(() => {
      dispatch(setNotification([]));
      getNotification();
    });
  };

  const handleRead = (messageId: any) => {
    readSingleNotification({
      notificationId: messageId,
      isRead: "read",
    }).then(() => {
      dispatch(setRemoveMessageId(messageId));
      getNotification();
    });
  };
  const dateDifference = (date: any) => {
    const providedDate: any = new Date(date);

    // Current date
    const currentDate: any = new Date();

    // Calculate the difference in milliseconds
    const timeDifference = currentDate - providedDate;

    // Define the time units in milliseconds
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;

    // Determine the largest time unit
    if (timeDifference < hour) {
      const minutes = Math.floor(timeDifference / minute);
      if (minutes === 1) {
        return "1min ago";
      } else if (minutes === 0) {
        return "just now";
      } else {
        return `${minutes} min ago`;
      }
    } else if (timeDifference < day) {
      const hours = Math.floor(timeDifference / hour);
      if (hours === 1) {
        return "1 h ago";
      } else {
        return `${hours} hours ago`;
      }
    } else {
      const days = Math.floor(timeDifference / day);
      if (days === 1) {
        return "1 day ago";
      } else {
        return `${days} days ago`;
      }
    }
  };
  // const getTime = (dateString: any) => {
  //   const date = new Date(dateString);

  //   // Extract the hours and minutes in 12-hour format
  //   const hours = date.getUTCHours();
  //   const minutes = date.getUTCMinutes();
  //   let timeString;

  //   if (hours >= 12) {
  //     timeString = `${(hours % 12).toString().padStart(2, "0")}:${minutes
  //       .toString()
  //       .padStart(2, "0")} PM`;
  //   } else {
  //     timeString = `${hours.toString().padStart(2, "0")}:${minutes
  //       .toString()
  //       .padStart(2, "0")} AM`;
  //   }

  //   return timeString;
  // };
  const consent2 =
    user?.type !== "shopper" ? user?.planHistoryId?.active : true;
  const consent =
    user?.type !== "shopper" && location.pathname.startsWith("/myaccount")
      ? true
      : user?.type !== "shopper"
      ? user?.planHistoryId?.active
      : true;
  const actionSX = {
    mt: "6px",
    ml: 1,
    top: "auto",
    right: "auto",
    alignSelf: "flex-start",

    transform: "none",
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = (id: string) => {
    setOpenDialog(true);
    dispatch(setNotification(markNotificationAsRead(id)));
  };
  function markNotificationAsRead(idToMarkAsRead: string): Notification[] {
    const updatedNotifications = notification.map((notify: any) => {
      if (notify._id === idToMarkAsRead) {
        // Update isRead status to "read" for the specified notification
        return { ...notify, isRead: "read" };
      }
      return notify;
    });

    return updatedNotifications;
  }

  // const downLG = useMediaQuery(theme.breakpoints.down("sm"));
  const timezone = user?.otherSetting?.timeZone;
  const dateFormat = user?.otherSetting?.dateFormat;
  const shouldSetTrue = notification.some(
    (value: any) => value?.isRead !== "read"
  );
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (anchorRef.current && !anchorRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      <>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Passion+One&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=DM+Serif+Text&display=swap"
        />
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          TransitionComponent={PopupTransition}
          sx={{ zIndex: 10001 }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
          PaperProps={{
            sx: {
              borderRadius: 8, // Set the border-radius for the entire modal
            },
          }}
        >
          <Box p={3} height={"50vh"} overflow={"scroll"}>
            <Stack
              direction="row-reverse"
              justifyContent={"space-between"}
              sx={{ mt: 1, mr: 1 }}
            >
              <CloseCircle
                onClick={handleCloseDialog}
                style={{ cursor: "pointer" }}
              />
              <DialogTitle sx={{ textAlign: "center", marginTop: "-18px" }}>
                <Typography variant="h3" color="primary" fontWeight="bold">
                  Notification
                </Typography>
              </DialogTitle>
              <Typography></Typography>
            </Stack>

            <Divider />
            <ListItemText
              primary={
                <Typography variant="h4" color="primary">
                  {selectedData?.subject}
                </Typography>
              }
              secondary={
                <Stack flexDirection="row">
                  <Typography variant="subtitle1" color="textSecondary" mr={1}>
                    Date:
                  </Typography>
                  <Stack direction="column">
                    <Typography variant="body1">
                      {formatDate(
                        selectedData?.updated_at,
                        dateFormat,
                        timezone
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              }
            />
            <Typography variant="body2" color="textPrimary">
              {selectedData?.body}
            </Typography>
          </Box>
        </Dialog>
      </>
      <BuyerModal
        handleClose={handleCloseBuyer}
        open={BuyerOpen}
        id={BuyerId}
      />
      <Popper
        placement={matchesXs ? "bottom" : "bottom"}
        open={open}
        anchorEl={anchorRef.current}
        sx={{
          zIndex: 10000,
          borderRadius: 0,
          marginTop: "16px !important",
          // marginRight:matchesXs ? '8px !important' : '8px',
          mr: matchesXs ? 0 : 1,
        }}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position={matchesXs ? "top" : "top-right"}
            sx={{ overflow: "hidden" }}
            in={open}
            {...TransitionProps}
          >
            {notification && notification.length === 0 ? (
              <>
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.z1,
                    borderRadius: 0,
                    width: "100%",
                    minWidth: 275,
                    maxWidth: 420,
                    textAlign: "center",
                    [theme.breakpoints.down("md")]: {
                      maxWidth: 285,
                    },
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard sx={{ border: "none" }}>
                      {consent2 ? "No notification yet" : "No subscription"}
                      {/* <Stack direction="row" justifyContent="center">
                        <Link
                          to={""}
                          color="primary"
                          style={{ textDecoration: "none" }}
                        >
                          <Typography
                            sx={{
                              color: "#0A4A8A",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {" View All Notification >"}
                          </Typography>
                        </Link>
                      </Stack> */}
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              </>
            ) : (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  borderRadius: 0,
                  width: "100%",
                  mr: { lg: 0, md: 0, sm: 1, xs: 1 },
                  minWidth: 270,
                  maxWidth: 480,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 285,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard
                    elevation={1}
                    border={false}
                    sx={{ borderRadius: "0px" }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="h5">Notifications</Typography>
                      {/* <Typography variant="h6" color="primary">
                        <Link
                          to={"#"}
                          onClick={markAllAsRead}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Mark all read
                        </Link>
                      </Typography> */}
                    </Stack>

                    <List
                      component="nav"
                      sx={{
                        "& .MuiListItemButton-root": {
                          // p: 1.5,
                          my: 1.5,
                          border: `1px solid ${theme.palette.divider}`,
                          "&:hover": {
                            bgcolor: "primary.lighter",
                            borderColor: theme.palette.primary.light,
                          },
                          "& .MuiListItemSecondaryAction-root": {
                            ...actionSX,
                            position: "relative",
                          },
                        },
                        height: "100%",
                        maxHeight: "70vh",
                        minHeight: "15vh",
                        overflowY: "auto",
                      }}
                    >
                      {consent2 ? (
                        notification &&
                        notification.length > 0 &&
                        notification.map(
                          (value: any) =>
                            value?.userId?._id && (
                              <>
                                <Box
                                  sx={{
                                    mb: 1,
                                    // p: 1,
                                    // py: 0.5,

                                // borderRadius: "10px",
                                backgroundColor:
                                  value?.isRead === "read"
                                    ? "#e8e8e8"
                                    : "#ffffff",
                              }}
                            >
                              <ListItem
                                onClick={() => {
                                  // if (value?.isRead !== "read") {
                                  setSelectedData(value);
                                  if (
                                    value?.type === "requestFeedback" &&
                                    user?.type === "shopper"
                                  ) {
                                    if (
                                      !user?.requestFeedbackSellerId.includes(
                                        value?.userId?._id
                                      )
                                    ) {
                                      updateLogin(
                                        true,
                                        {
                                          ...user,
                                          requestFeedbackSellerId: [
                                            ...user?.requestFeedbackSellerId,
                                            value?.userId?._id,
                                          ],
                                        },
                                        socket
                                      );
                                    }
                                    navigate("/customer", {
                                      state: { _id: value?.userId?._id },
                                    });
                                    dispatch(
                                      setNotification(
                                        markNotificationAsRead(value?._id)
                                      )
                                    );
                                  } else if (
                                    value?.type === "addReview" &&
                                    user?.type !== "shopper"
                                  ) {
                                    // console.log(location.pathname);
                                    // navigate("/customer", {
                                    //   state: { _id: value?.userId?._id },
                                    // });
                                    if (location.pathname === "/myaccount") {
                                      dispatch(setSettingCount(5));
                                    } else {
                                      navigate("/myaccount", {
                                        state: { panel: 5 },
                                      });
                                      dispatch(setSettingCount(5));
                                    }
                                    if (value?.isRead !== "read") {
                                      handleRead(value?._id);
                                    }
                                    dispatch(
                                      setNotification(
                                        markNotificationAsRead(value?._id)
                                      )
                                    );
                                  } else if (value?.type === "shopperSignUp") {
                                    setBuyerId(value?.userId?._id);
                                    // setBuyerId(value?.userId);
                                    setBuyerOpen(true);
                                  } else if (
                                    value?.type === "consultantSignUp"
                                  ) {
                                    // navigate("/customer", {
                                    //   state: { _id: value?.receiverUserId?._id },
                                    // });
                                    navigate("/customer", {
                                      state: { _id: value?.userId?._id },
                                    });
                                  } else if (
                                    value?.type === "chatMessageNotification"
                                  ) {
                                    dispatch(setUserSwitching(true));
                                    dispatch(setChatIdeal(false));
                                    dispatch(setChatSkip(1));
                                    dispatch(setTotalChatCount(0));
                                    dispatch(resetActiveChannelMessages([]));
                                    localStorage.setItem(
                                      "recieverId",
                                      value?.userId?._id
                                    );
                                    dispatch(
                                      setCurrentRecieverId(value?.userId?._id)
                                    );

                                    dispatch(
                                      setActiveChannelUser(value?.userId)
                                    );
                                    getRoomId(
                                      { socket: socket, user: user },
                                      value?.userId
                                    );
                                    dispatch(
                                      setSettingCount(
                                        user?.type !== "shopper" ? 4 : 2
                                      )
                                    );
                                    navigate("/myaccount", {
                                      state: {
                                        userData: value?.userId,
                                      },
                                    });
                                  } else if (value?.type === "addCar") {
                                    navigate("/view", {
                                      state: {
                                        _id: value?.carId,
                                      },
                                    });
                                  } else {
                                    handleOpenDialog(value?._id);
                                  }
                                  if (value?.isRead !== "read") {
                                    handleRead(value?._id);
                                  }
                                  // }
                                }}
                                sx={{
                                  cursor: "pointer",
                                }}
                              >
                                {/* <ListItemAvatar>
                                  <Avatar>
                                    <Gift size={20} variant="Bold" />
                                  </Avatar>
                                </ListItemAvatar> */}
                                    <ListItemText
                                      primary={
                                        <Typography variant="body1">
                                          {value?.subject ===
                                          "Request for feedback"
                                            ? `${
                                                value?.receiverUserId?.firstName
                                                  ? value?.receiverUserId
                                                      ?.firstName
                                                  : ""
                                              } ${
                                                value?.receiverUserId?.lastName
                                                  ? value?.receiverUserId
                                                      ?.lastName
                                                  : ""
                                              } Requested for feedback`
                                            : value?.subject ===
                                              "A review from the shopper."
                                            ? `${
                                                value?.receiverUserId?.firstName
                                                  ? value?.receiverUserId
                                                      ?.firstName
                                                  : ""
                                              } ${
                                                value?.receiverUserId?.lastName
                                                  ? value?.receiverUserId
                                                      ?.lastName
                                                  : ""
                                              } gave a Review`
                                            : value?.subject}
                                        </Typography>
                                      }
                                      secondary={
                                        <i>
                                          {dateDifference(value?.created_at)}
                                        </i>
                                      }
                                    />
                                    {/* <ListItemSecondaryAction
                                sx={{ position: "absolute", top: 3, right: 0 }}
                              >
                                <Typography variant="caption" noWrap>
                                  {getTime(value?.updated_at)}
                                </Typography>
                              </ListItemSecondaryAction> */}
                                    {/* <ListItemIcon
                              onClick={() => {
                                handleRead(value?._id);
                              }}
                              sx={{
                                position: "absolute",
                                top: 0.5,
                                right: 0.5,
                                transform: "rotate(45deg)",
                              }}
                            >
                              <Add />
                            </ListItemIcon> */}
                                  </ListItem>

                                  {/* <Divider /> */}
                                </Box>

                            <Divider
                              sx={{
                                mb: 1,
                                mt: value?.isRead === "read" ? -0.9 : 0,
                              }}
                            />
                          </>
                        ))
                      ) : (
                        <>No Subscription</>
                      )}
                    </List>
                    <Stack direction="row" justifyContent="center">
                      {/* <Link
                        to={""}
                        color="primary"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          sx={{
                            color: "#0A4A8A",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {" View All Notification >"}
                        </Typography>
                      </Link> */}
                    </Stack>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
      <AppBar
        position="sticky"
        sx={{
          background: "#ffffff",
          px: { xs: 2, sm: 0, md: 0, lg: 5 },
          boxShadow: "0px 6px 14px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Toolbar
          sx={{
            // cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            p: 0,
            "& .logo-text": {
              textDecoration: "none",
              color: "#0A4A8A",
              fontFamily: "Passion One, sans-serif",
              letterSpacing: "2px",
              fontSize: { xs: "30px", sm: "40px", md: "40px", lg: "40px" },
              fontWeight: 700,
              lineHeight: "70px",
              userSelect: "none",
              pointerEvents: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: { xs: "space-between" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                maxWidth: {
                  lg: "350px",
                  ms: "350px",
                  sm: "320px",
                  xs: "200px",
                },
                mt: { lg: 0, md: 0, sm: 0, xs: 0.8 },
                justifyContent: "flex-start",
              }}
              onClick={async () => {
                const data: any = await getNotification();
                if (data) {
                  dispatch(setNotification(data?.data?.data?.data));
                  dispatch(setNotificationCount(data?.data?.data?.Count));
                }
              }}
            >
              <Link
                to={"https://www.automatchpros.com/"}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {data && data?.logo ? (
                  <Typography variant="h4" className="logo-text">
                    <Avatar
                      variant="square"
                      src={`data:${data?.logo?.mimetype};base64,${data?.logo?.file}`}
                      alt="img"
                      sx={{
                        width: "100%",
                        height: "auto",
                        mt: 1,
                        // maxHeight: "45px",
                      }}
                    />
                  </Typography>
                ) : (
                  <Typography variant="h4" className="logo-text">
                    AUTOMATCHPROS
                  </Typography>
                )}
                {/* <Typography variant="h4" className="logo-text">
                  AUTOMATCHPROS
                </Typography> */}
              </Link>
            </Box>
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                justifyContent: "flex-end",
              }}
            >
              {/* <IconButton
              sx={{
                cursor: "pointer",
                marginRight: 4,
                color: "#666666",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "29px",
                letterSpacing: "0.04em",
                borderRadius: "50%", // Make the button rounded
                border: "1px solid #CCCCCC", // Add a border
                transition: "border-color 0.3s, color 0.3s", // Add transition effect
                "&:hover": {
                  color: "#BF9422",
                  borderColor: "#BF9422", // Change border color on hover
                },
              }}
            >
              <Badge
                variant="dot"
                color="error"
                onClick={() => {
                  setOpen(true);
                }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Notification variant={"Bold"} color="black" />
              </Badge>
            </IconButton> */}
              {matchesXs && (
                <Tooltip title="Notification">
                  <IconButton
                    sx={{
                      cursor: "pointer",
                      marginRight: 1,
                      color: "#666666",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "29px",
                      letterSpacing: "0.04em",
                      borderColor: open ? "#BF9422" : "#ECF1F8",
                      borderRadius: "50%", // Make the button rounded
                      border: "0.5px solid #ECF1F8", // Add a border
                      transition: "border-color 0.3s, color 0.3s", // Add transition effect
                      "&:hover": {
                        color: "#BF9422",
                        borderColor: "#BF9422", // Change border color on hover
                      },
                    }}
                    ref={anchorRef}
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    <Badge
                      variant={
                        !shouldSetTrue
                          ? "standard"
                          : consent2
                          ? "dot"
                          : "standard"
                      }
                      color="error"
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                      <Notification variant={"Bold"} color="black" />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                size="large"
                edge="end"
                aria-label="menu"
                onClick={toggleDrawer}
                sx={{ marginRight: -1 }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mr: { lg: 13, md: 1 },
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Tooltip
                title={
                  <Typography sx={{ fontSize: "15px" }}>
                    Start your search effortlessly! <br />
                    <br />
                    Type in any keyword you have in mind. <br />
                    <br /> This could be a car brand such as "Honda" or "Tesla",
                    a model name like "F150" or "Civic", the name of a
                    consultant, a specific dealership, a city, or a zip code.
                    <br />
                    <br /> Additionally, you can specify if you're looking for a
                    consultant with special abilities, such as Korean-speaking
                    or Spanish-speaking, to ensure you find the perfect car
                    consultant or buyer who meets all your needs.
                  </Typography>
                }
              >
                <IconButton
                  sx={{
                    "&:hover": { backgroundColor: "transparent" },
                    width: "42px",
                    height: "42px",
                  }}
                >
                  <Information style={{ height: "42px", width: "42px" }} />
                </IconButton>
              </Tooltip>

              <Autocomplete
                options={options}
                getOptionLabel={(option) => option}
                disabled={!consent2}
                value={search}
                onChange={(e: any, newValue: any) => {
                  handleSearch(newValue, 1);
                }}
                onKeyPress={handleKeyPress}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: <SearchNormal1 size="18" />,
                    }}
                    placeholder="Search here..."
                    fullWidth
                    sx={{
                      width: "22rem",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "15px",
                      },
                    }}
                    onChange={(e: any) => {
                      handleSearch(e.target.value, 0);
                    }}
                  />
                )}
              />
            </Box>
            <Dialog
              open={dialogOpen2}
              onClose={handleCloseDialog2}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Typography textAlign={"center"} variant="h4" margin={1}>
                Confirm Logout
              </Typography>
              <Divider />
              <DialogContent>
                <Typography variant="body1">
                  Are you sure you want to log out?
                </Typography>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center" }}>
                <Button onClick={handleCloseDialog2} variant="outlined">
                  Cancel
                </Button>
                <Button
                  onClick={handleLogout}
                  variant="contained"
                  color="error"
                  autoFocus
                >
                  Logout
                </Button>
              </DialogActions>
            </Dialog>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Tooltip title="Home">
                <IconButton
                  sx={{
                    cursor: "pointer",
                    marginRight: 4,
                    color: currentUrl.includes("/home") ? "#BF9422" : "#666666",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "29px",
                    letterSpacing: "0.04em",
                    borderRadius: "50%", // Make the button rounded
                    // border: "1px solid #CCCCCC", // Add a border
                    border: "0.5px solid #ECF1F8",
                    backgroundColor: currentUrl.includes("/home")
                      ? "#D1E8FF"
                      : "#fff",
                    borderColor: currentUrl.includes("/home")
                      ? "#BF9422"
                      : "#ECF1F8",
                    transition: "border-color 0.3s, color 0.3s", // Add transition effect
                    "&:hover": {
                      color: "#BF9422",
                      borderColor: "#BF9422", // Change border color on hover
                    },
                  }}
                  onClick={() => {
                    navigate("/home");
                    if (consent2) {
                      getNotification();
                    }
                  }}
                >
                  <Home variant="Bold" color="#0A4A8A" />
                </IconButton>
              </Tooltip>
              {/* {user?.type === "shopper" && (
                <Tooltip title="Wishlist">
                  <IconButton
                    sx={{
                      cursor: "pointer",
                      marginRight: 4,
                      color: currentUrl.includes("/myaccount")
                        ? "#BF9422"
                        : "#666666",
                      display: { xs: "none", md: "flex" },
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "29px",
                      letterSpacing: "0.04em",
                      backgroundColor: currentUrl.includes("/myaccount")
                        ? "#D1E8FF"
                        : "#FFF",
                      borderRadius: "50%", // Make the button rounded
                      // border: "1px solid #CCCCCC", // Add a border
                      border: "0.5px solid #ECF1F8",
                      borderColor: currentUrl.includes("/myaccount")
                        ? "#BF9422"
                        : "#ECF1F8",
                      transition: "border-color 0.3s, color 0.3s", // Add transition effect
                      "&:hover": {
                        color: "#BF9422",
                        borderColor: "#BF9422", // Change border color on hover
                      },
                    }}
                    onClick={() => {
                      navigate("/myaccount", { state: { panel: 1 } });
                    }}
                  >
                    <Heart variant="Bold" color="red" />
                  </IconButton>
                </Tooltip>
              )} */}
              {/* {user?.type === "shopper" && (
                <IconButton
                  sx={{
                    cursor: "pointer",
                    marginRight: 4,
                    color: currentUrl.includes("/consultant") ? "#BF9422" : "#666666",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "29px",
                    letterSpacing: "0.04em",
                    borderRadius: "50%", // Make the button rounded
                    // border: "1px solid #CCCCCC", // Add a border
                    border: "0.5px solid #ECF1F8",
                    borderColor: currentUrl.includes("/consultant")
                      ? "#BF9422"
                      : "#ECF1F8",
                    transition: "border-color 0.3s, color 0.3s", // Add transition effect
                    "&:hover": {
                      color: "#BF9422",
                      borderColor: "#BF9422", // Change border color on hover
                    },
                  }}
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <UserOctagon variant="Bold" color="black" />
                </IconButton>
              )} */}
              {!matchesXs && (
                <Tooltip title="Notification">
                  <IconButton
                    sx={{
                      cursor: "pointer",
                      marginRight: 4,
                      color: "#666666",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "29px",
                      letterSpacing: "0.04em",
                      borderColor: open ? "#BF9422" : "#ECF1F8",
                      borderRadius: "50%", // Make the button rounded
                      border: "1px solid #ECF1F8", // Add a border
                      transition: "border-color 0.3s, color 0.3s", // Add transition effect
                      "&:hover": {
                        color: "#BF9422",
                        borderColor: "#BF9422", // Change border color on hover
                      },
                    }}
                  >
                    <Badge
                      variant={
                        !shouldSetTrue
                          ? "standard"
                          : consent2
                          ? "dot"
                          : "standard"
                      }
                      color="error"
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      ref={anchorRef}
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      <Notification variant={"Bold"} color="black" />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  color: "black",
                }}
              >
                <Box
                  sx={{
                    mr: 1,
                  }}
                >
                  {user?.imageId?.file ? (
                    <Avatar
                      sx={{
                        borderRadius: "50%",
                        border: "1px solid #ECF1F8",
                      }}
                      alt="Selected"
                      src={`data:${user?.imageId?.mimetype};base64,${user?.imageId?.file}`}
                    />
                  ) : (
                    <Avatar
                      src="h"
                      sx={{
                        bgcolor: blue[50],
                        borderRadius: "50%",
                        border: "1px solid #ECF1F8",
                        color: blue[300],
                      }}
                    />
                  )}
                </Box>
                <Box onClick={handleClick}>
                  <Typography
                    variant="h6"
                    sx={{
                      // display: "flex",
                      cursor: "pointer",
                      justifyContent: "left",
                      alignItems: "center",
                      fontWeight: 400,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "14px",
                        lg: "14px",
                        xl: "14px",
                      },
                      lineHeight: {
                        xs: "14px",
                        sm: "18px",
                        md: "22px",
                        lg: "20px",
                        xl: "20px",
                      },
                    }}
                  >
                    Hello,
                    <br />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "capitalize",
                        fontWeight: 600,
                        fontSize: "15px",
                        // lineHeight: "26px",
                      }}
                    >
                      {user?.nickName
                        ? user?.nickName?.slice(0, 10)
                        : user?.firstName?.slice(0, 10)}
                      <ArrowDown2 variant="Bold" size="24" />
                    </span>
                  </Typography>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {/* You can customize the content of the menu items */}
                  <MenuItem
                    sx={{
                      color: "#333333",
                      fontWeight: 600,
                      textTransform: "capitalize",
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "14px",
                        lg: "16px",
                        xl: "16px",
                      },
                      lineHeight: {
                        xs: "12px",
                        sm: "14px",
                        md: "18px",
                        lg: "22px",
                        xl: "22px",
                      },
                    }}
                  >
                    Welcome,{" "}
                    {user?.firstName?.length >= 10
                      ? `${user?.firstName?.slice(0, 10)}...`
                      : `${user?.firstName} ${user?.lastName}`?.length > 10
                      ? `${user?.firstName} ${user?.lastName?.slice(
                          0,
                          10 - user?.firstName?.length
                        )}...`
                      : user?.firstName + " " + user?.lastName}
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleClose();
                      if (
                        user?.type === "seller" &&
                        !user?.planHistoryId?.active
                      ) {
                        dispatch(setSettingCount(2));
                        localStorage.setItem("settingIndexval", "2");
                      } else {
                        dispatch(setSettingCount(0));

                        localStorage.setItem("settingIndexval", "0");
                      }
                      navigate("/myaccount");
                    }}
                    sx={{ color: "#666666" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          color: "#333333",
                          fontWeight: 400,
                          textTransform: "capitalize",
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "12px",
                            lg: "14px",
                            xl: "14px",
                          },
                          lineHeight: {
                            xs: "12px",
                            sm: "14px",
                            md: "16px",
                            lg: "18px",
                            xl: "18px",
                          },
                        }}
                      >
                        <User variant="Bold" style={{ color: "#666666" }} />
                        My Account
                      </Typography>
                    </Box>
                  </MenuItem>
                  {user?.type === "shopper" && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        dispatch(setSettingCount(1));
                        localStorage.setItem("settingIndexval", "1");
                        if (location.pathname === "/myaccount") {
                          dispatch(setSettingCount(1));
                        } else {
                          navigate("/myaccount", { state: { panel: 1 } });
                        }
                      }}
                      sx={{ color: "#666666" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "center",
                            color: "#333333",
                            fontWeight: 400,
                            textTransform: "capitalize",
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "12px",
                              lg: "14px",
                              xl: "14px",
                            },
                            lineHeight: {
                              xs: "12px",
                              sm: "14px",
                              md: "16px",
                              lg: "18px",
                              xl: "18px",
                            },
                          }}
                        >
                          <Heart variant="Bold" style={{ color: "#666666" }} />{" "}
                          Wishlist
                        </Typography>
                      </Box>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      if (
                        user?.type === "seller" &&
                        !user?.planHistoryId?.active
                      ) {
                        dispatch(setSettingCount(2));
                        localStorage.setItem("settingIndexval", "2");
                      } else {
                        dispatch(
                          setSettingCount(user?.type === "shopper" ? 2 : 4)
                        );
                        localStorage.setItem(
                          "settingIndexval",
                          user?.type === "shopper" ? "2" : "4"
                        );
                      }
                      getNotification();
                      if (location.pathname === "/myaccount") {
                      } else {
                        // dispatch(setSettingCount(2));
                        if (
                          user?.type === "seller" &&
                          !user?.planHistoryId?.active
                        ) {
                          navigate("/myaccount", {
                            state: { panel: 2 },
                          });
                        } else {
                          navigate("/myaccount", {
                            state: { panel: user?.type === "shopper" ? 2 : 4 },
                          });
                        }
                      }
                    }}
                    sx={{ color: "#666666" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          color: "#333333",
                          fontWeight: 400,
                          textTransform: "capitalize",
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "12px",
                            lg: "14px",
                            xl: "14px",
                          },
                          lineHeight: {
                            xs: "12px",
                            sm: "14px",
                            md: "16px",
                            lg: "18px",
                            xl: "18px",
                          },
                        }}
                      >
                        <Message variant="Bold" style={{ color: "#666666" }} />{" "}
                        Messages
                      </Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    onClick={handleOpenDialog2}
                    sx={{ color: "#666666" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          color: "#333333",
                          fontWeight: 400,
                          textTransform: "capitalize",
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "12px",
                            lg: "14px",
                            xl: "14px",
                          },
                          lineHeight: {
                            xs: "12px",
                            sm: "14px",
                            md: "16px",
                            lg: "18px",
                            xl: "18px",
                          },
                        }}
                      >
                        <LogoutCurve
                          variant="Bold"
                          style={{
                            transform: "rotate(180deg)",
                            color: "#666666",
                          }}
                        />
                        Logout
                      </Typography>
                    </Box>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            width: "220px",
            background: "#fff",
          },
        }}
      >
        <Box sx={{ px: 2, display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            size="large"
            edge="end"
            aria-label="close"
            onClick={toggleDrawer}
          >
            <CloseSquare />
          </IconButton>
        </Box>

        <Box sx={{ p: 2 }}>
          <Box sx={{ flexGrow: 1, my: 2 }}>
            <Autocomplete
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={options}
              getOptionLabel={(option) => option}
              value={search}
              onChange={(e: any, newValue: any) => {
                handleSearch(newValue, 1);
              }}
              onKeyPress={handleKeyPress}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <SearchNormal1 size="18" />,
                  }}
                  placeholder="Search here..."
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "15px",
                    },
                  }}
                  onChange={(e: any) => {
                    handleSearch(e.target.value, 0);
                  }}
                />
              )}
            />
          </Box>
          {/* <Typography
            variant="body1"
            sx={{
              cursor: "pointer",
              marginBottom: 2,
              color: "#666666",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "29px",

              letterSpacing: "0.04em",
              "&:hover": {
                color: "#BF9422",
                borderBottom: "3px solid #BF9422",
              },
            }}
          >
            Favourite
          </Typography> */}
          {/* <Typography
            variant="body1"
            sx={{
              cursor: "pointer",
              marginBottom: 2,
              color: "#666666",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "29px",

              letterSpacing: "0.04em",
              "&:hover": {
                color: "#BF9422",
                borderBottom: "3px solid #BF9422",
              },
            }}
          >
            Notification
          </Typography> */}
          {/* <Typography
            variant="body1"
            sx={{
              cursor: "pointer",
              marginBottom: 2,
              color: "#666666",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "29px",

              letterSpacing: "0.04em",
              "&:hover": {
                color: "#BF9422",
                borderBottom: "3px solid #BF9422",
              },
            }}
          >
            Settings
          </Typography> */}
          <Typography
            variant="body1"
            sx={{
              cursor: "pointer",
              marginBottom: 2,
              color: "#666666",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "29px",

              letterSpacing: "0.04em",
              "&:hover": {
                color: "#BF9422",
                borderBottom: "3px solid #BF9422",
              },
            }}
            onClick={() => {
              navigate("/home");
              toggleDrawer();
            }}
          >
            Home
          </Typography>
          <Typography
            variant="body1"
            sx={{
              cursor: "pointer",
              marginBottom: 2,
              color: "#666666",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "29px",

              letterSpacing: "0.04em",
              "&:hover": {
                color: "#BF9422",
                borderBottom: "3px solid #BF9422",
              },
            }}
            onClick={() => {
              dispatch(setSettingCount(consent ? 0 : 2));
              localStorage.setItem("settingIndexval", consent ? "0" : "2");
              navigate("/myaccount");
              toggleDrawer();
            }}
          >
            My Account
          </Typography>

          {user?.type === "shopper" && (
            <Typography
              variant="body1"
              sx={{
                cursor: "pointer",
                marginBottom: 2,
                color: "#666666",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "29px",
                letterSpacing: "0.04em",
                "&:hover": {
                  color: "#BF9422",
                  borderBottom: "3px solid #BF9422",
                },
              }}
              onClick={() => {
                dispatch(setSettingCount(1));
                getNotification();
                localStorage.setItem("settingIndexval", "1");
                navigate("/myaccount", { state: { panel: 1 } });
                toggleDrawer();
              }}
            >
              Wishlist
            </Typography>
          )}

          <Typography
            variant="body1"
            sx={{
              cursor: "pointer",
              marginBottom: 2,
              color: "#666666",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "29px",

              letterSpacing: "0.04em",
              "&:hover": {
                color: "#BF9422",
                borderBottom: "3px solid #BF9422",
              },
            }}
            onClick={() => {
              handleClose();
              if (consent) {
                dispatch(setSettingCount(user?.type === "shopper" ? 2 : 4));
                localStorage.setItem(
                  "settingIndexval",
                  user?.type === "shopper" ? "2" : "4"
                );
                navigate("/myaccount", {
                  state: { panel: user?.type === "shopper" ? 2 : 4 },
                });
              } else {
                Snackbar("Please purchase a new subscription", "warning");
              }
              toggleDrawer();
            }}
          >
            Message
          </Typography>
          {/* {user?.type === "shopper" && (
            <Typography
              variant="body1"
              sx={{
                cursor: "pointer",
                marginBottom: 2,
                color: "#666666",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "29px",
                letterSpacing: "0.04em",
                "&:hover": {
                  color: "#BF9422",
                  borderBottom: "3px solid #BF9422",
                },
              }}
              onClick={() => {
                navigate("/consultant", { state: { panel: 1 } });
              }}
            >
              View Consultant
            </Typography>
          )} */}

          <Typography
            variant="body1"
            sx={{
              cursor: "pointer",
              marginBottom: 2,
              color: "#666666",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "29px",

              letterSpacing: "0.04em",
              "&:hover": {
                color: "#BF9422",
                borderBottom: "3px solid #BF9422",
              },
            }}
            onClick={handleLogout}
          >
            Logout
          </Typography>

          {/* <Typography
            variant="body1"
            onClick={() => scrollToSection("Benefits")}
            sx={{
              cursor: "pointer",
              marginBottom: 2,
              color: "#666666",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "29px",

              letterSpacing: "0.04em",
              "&:hover": {
                color: "#BF9422",
                borderBottom: "3px solid #BF9422",
              },
            }}
          >
            Benefits
          </Typography> */}
          {/* <Button
            variant="contained"
            onClick={() => scrollToSection("joinUsSection")}
            startIcon={<User variant="Bold" />}
            sx={{
              backgroundColor: "#0A4A8A",

              "&:hover": {
                backgroundColor: "#BF9422",
              },
            }}
          >
            Join US
          </Button> */}
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar2;
